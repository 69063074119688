// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/marcin/Dokumenty/restimo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-message-send-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/message-send.js" /* webpackChunkName: "component---src-pages-message-send-js" */),
  "component---src-pages-pay-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-payment-failed-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payment-success-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-payment-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payments-contact-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/payments-contact.js" /* webpackChunkName: "component---src-pages-payments-contact-js" */),
  "component---src-pages-restaurants-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-scan-qr-code-js": () => import("/home/marcin/Dokumenty/restimo/src/pages/scan-qr-code.js" /* webpackChunkName: "component---src-pages-scan-qr-code-js" */)
}

